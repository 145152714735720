import { Environment } from './environment.interface';

export const environment: Environment = {
  environment: 'test',
  intercomAppId: 'nel1ptj2',
  production: true,
  rollbarAccessToken: 'e11c7f9a600e45ecb8a6fbe0789c1016',
  ipBaseGeoKey: 'GqQQrDQJStM7UwEphJccOuCEmj6Blx1aiwWFrUVX',
  mixpanelToken: '8951290cc37c8b469146c6596050e544',
  mixpanelId: '1333630',
  retryConnectionFailures: true,
  azure: {
    domainMappings: {
      'tradecloud1test.onmicrosoft.com': 'tradecloud',
    },
    app: {
      damen: {
        clientId: '',
        tenantId: '',
      },
      elcee: {
        clientId: '',
        tenantId: '',
      },
      huisman: {
        clientId: '',
        tenantId: '',
      },
      wassenburg: {
        clientId: '',
        tenantId: '',
      },
      tradecloud: {
        clientId: '19d9e27f-fa01-47a0-b051-ecd9efe2834d',
        tenantId: '7855c9de-3140-440b-9550-3211be68e2a2',
      },
    },
  },
};
